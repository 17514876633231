import cn from 'classnames';
import React from 'react';
import { ImageWithFallback } from '../../ImageWithFallback/ImageWithFallback';
import styles from './RecommendationItem.module.scss';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import HotelIcon from '@mui/icons-material/Hotel';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import SmallLogo from '../../../public/small-white-logo.svg';
import Link from 'next/link';

function getArticleLink(type, subtype, textId, country, city) {
  if (!type || !subtype || !textId) {
    return null;
  }
  if (type === 'Hotels' && subtype === 'Reviews') {
    type = 'hotel';
    subtype = 'review';
  }

  if (country) {
    return `/destinations/${country}/${city ? city : textId}`;
  }

  if (type === 'Offers') {
    return '/offers' + '/' + textId;
  }

  return '/' + type.toLowerCase() + '/' + subtype.toLowerCase() + '/' + textId;
}

export const RecommendationItem = ({ item, imageOut = true, props }) => {
  if (item.image) {
    if (item.image?.[0] === '/') {
      item.image = item.image?.replace('/', '');
    }
    item.image = item.image?.replace('view/image/', '');
  }
  if (item.topImage) {
    if (item.topImage.image?.[0] === '/') {
      item.topImage.image = item.topImage.image?.replace('/', '');
    }
    item.topImage.image = item.topImage.image?.replace('view/image/', '');
  }
  const cardRef = React.createRef();

  const getIcon = () => {
    switch(item.type) {
      case 'Airlines': return <AirplanemodeActiveIcon className="m-auto text-white" />;
      case 'Hotels': return <HotelIcon className="m-auto text-white" />;
      case 'Destinations': return <PinDropRoundedIcon className="m-auto text-white" />;
      case 'Offers': return <LocalOfferOutlinedIcon className="m-auto text-white" />;
      default: return <SmallLogo className="m-auto text-white" />;
    }
  };

  return (
    <Link
      href={`${getArticleLink(
        item.type,
        item.subtype,
        item.textId,
        item.country,
        item.city
      )}`}
      aria-label={item.heading}
      {...props}
    >
      <div className={cn(styles.card, 'relative mb-6')}>
        <div className={styles.recomendationContainer}>
          <div
            className={cn(styles.icon, {
              'bg-review-iconAirlines': item.type === 'Airlines',
              'bg-review-iconHotels': item.type === 'Hotels',
              'bg-review-iconDestinations': item.type === 'Destinations',
              'bg-review-iconOther':
                item.type !== 'Destinations' &&
                item.type !== 'Hotels' &&
                item.type !== 'Airlines',
            })}
          >
           {getIcon()}
          </div>
          <div className="h-full flex md:flex-col xs:flex-col" ref={cardRef}>
            <div className={cn(styles.imageContainer, imageOut && styles.imageOut)}>
              <ImageWithFallback
                src={process.env.mediaUrl + (item.topImage?.image || item.image) }
                objectFit="cover"
                layout="fill"
                alt={item.title + ' image'}
                sizes="(max-width: 1024px) 100vw, 220px"
              />
            </div>
            <div
              className={cn(
                styles.text,
                'relative flex flex-col justify-start items-start py-5',
                imageOut && 'pl-3'
              )}
            >
              <div className={cn(styles.type, 'font-libre')}>
                {item.overline
                  ? item.overline
                  : item.type.replace('s', '') +
                    ' ' +
                    item.subtype.replace('s', '')}

                {item.tag && <span className='ml-2'>{item.tag}</span>}
              </div>
              <div className={cn(styles.title, 'font-fragment')}>
                {item.subtype === 'Reviews' && 'Review of '}
                {item.subtype === 'Reviews' &&
                item.type === 'Hotels' &&
                !(item.title.includes('the') || item.title.includes('The'))
                  ? item.title
                  : item.title.startsWith('The')
                  ? item.title
                  : item.title.replace('The', 'the')}
                {!!item.cabinClass && ' ' + item.cabinClass}
              </div>
              <p
                className={cn(styles.content, 'font-libre')}
                dangerouslySetInnerHTML={{ __html: item.content }}
              ></p>

              <p className={styles.readMore}>Read more</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
