import { useSelector } from 'react-redux';
import styles from './OffersListSection.module.scss';
import { httpGet } from '../../utils/httpClient';
import { useEffect, useState } from 'react';
import { OfferCard } from '../OfferCard/OfferCard';

const getOfferItems = async (
  country,
  origin = '',
  destination = '',
  airline = '',
  cabinClass
) => {
  const params = [];

  if (country) {
    params.push(`country=${country}`);
  }

  if (origin) {
    params.push(`origin=${origin}`);
  }

  if (destination) {
    params.push(`destination=${destination}`);
  }

  if (airline) {
    params.push(`airline=${airline}`);
  }

  if (cabinClass) {
    params.push(`cabinClass=${cabinClass}`);
  }

  const urlParams = params.join('&');

  return await (
    await httpGet(`/api/offer/items?${urlParams}`)
  ).json();
};

export function OffersListSection({
  offers,
  country,
  origin,
  destination,
  airline,
  cabinClass,
  searchSource,
  openedOfferId
}) {
  const userStore = useSelector((state) => state.user.value);
  const [offerItems, setOfferItems] = useState([]);

  useEffect(() => {
    if (offers && offers.length > 0) {
      setOfferItems(offers);
    } else {
      const fetchOfferItems = async () => {
        const offerItems = await getOfferItems(
          country || userStore?.country,
          origin ? origin : '',
          destination,
          airline ? airline : '',
          cabinClass
        );
        setOfferItems(offerItems);
      };
      fetchOfferItems();
    }
  }, [offers]);


  return (
    <>
      {offerItems.map(
        (offer, index) =>
          <OfferCard key={index} data={offer} searchSource={searchSource} isOpened={offer.id === openedOfferId}/>
      )}

      {!offerItems.length && (
        <div className={styles.noResults}>No offers found </div>
      )}
    </>
  );
}
