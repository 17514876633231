import { domToReact } from "html-react-parser";
import ScrollableContent from "../ScrollableContent/ScrollableContent";
import { BookNow } from "../HotelReview/components/BookNow";
import { setImageUrl } from "../../utils/setImageUrl";
import { BCCarousel } from "../Carousel/Carousel";
import Image from 'next/image';

export const getTextEditorParseOptions = ({article, mediaWidth, mediaHeight, imageRef, styles}) => {
    const options = {
        replace: (domNode) => {
          if (domNode.type === 'tag' && domNode.name === 'a') {
            if (domNode.attribs?.href?.includes('http')) {
              if (domNode.attribs?.target === '_blank') {
                domNode.attribs.target = '_blank';
              }
    
              if (domNode.attribs?.rel?.includes('sponsored')) {
                domNode.attribs.rel = 'noreferrer noopener sponsored';
              } else {
                domNode.attribs.rel = 'noreferrer noopener nofollow';
              }
            }
          }
          if (
            domNode.type === 'tag' &&
            domNode.attribs.class === 'image-inline' &&
            domNode.children[0].name === 'video'
          ) {
            const videoSrc = domNode.children[0].children.find(
              (node) => node.name === 'source'
            ).attribs.src;
            return (
              <div className="video">
                <video
                  autoPlay
                  muted
                  loop
                  style={{ height: mediaHeight, width: mediaWidth }}
                >
                  <source src={videoSrc} />
                </video>
              </div>
            );
          }
          if (
            domNode.type === 'tag' &&
            domNode.name === 'video' &&
            !!domNode.attribs.src
          ) {
            const videoSrc =
              process.env.mediaUrl + domNode.attribs.src.replace('/', '');
            return (
              <video
                autoPlay
                muted
                loop
                style={{
                  height: imageRef?.current?.offsetWidth / 1.78,
                  width: imageRef?.current?.offsetWidth,
                }}
              >
                <source src={videoSrc} />
              </video>
            );
          }
    
          const findVideoNode = (domNode) => {
            if (!domNode) return null;
            if (domNode.name === 'video') {
              return domNode;
            } else {
              return findVideoNode(
                domNode.children.find((node) => node.type === 'tag')
              );
            }
          };
          const findImageNode = (domNode) => {
            if (domNode) {
              if (domNode.name === 'img') {
                return domNode;
              } else {
                const figureNode = domNode.children.find(
                  (node) => node.name === 'figure'
                );
                if (figureNode) {
                  return findImageNode(figureNode);
                } else {
                  return findImageNode(
                    domNode.children.find((node) => node.type === 'tag')
                  );
                }
              }
            } else {
              return <div>Wrong tag</div>;
            }
          };
          if (
            domNode.name === 'div' &&
            domNode.attribs.id === 'scrollable' &&
            domNode.children[0].name !== 'details'
          ) {
            let heading;
            const findHeading = (nodes) => {
              if (nodes.length) {
                nodes.forEach((node) => {
                  if (node.attribs?.id === 'heading') {
                    heading = [node];
                  } else if (node.children?.length) {
                    findHeading(node.children);
                  }
                });
              }
            };
            findHeading(domNode.children);
            const content = domNode.children.filter(
              (node) => node.attribs?.id !== 'heading'
            );
            return (
              <ScrollableContent
                heading={domToReact(heading, options)}
                content={domToReact(content, options)}
                style={styles.scrollableContent}
              />
            );
          }
          if (domNode.name === 'details') {
            let heading;
            const findHeading = (nodes) => {
              if (nodes.length) {
                nodes.forEach((node) => {
                  if (node.name === 'summary') {
                    heading = [node.children[0]];
                  } else if (node.children?.length) {
                    findHeading(node.children);
                  }
                });
              }
            };
            findHeading(domNode.children);
            const content = domNode.children.filter(
              (node) => node.attribs.class === 'accordion_content'
            );
            return (
              <ScrollableContent
                heading={domToReact(heading, options)}
                content={domToReact(content, options)}
                style={styles.scrollableContent}
              />
            );
          }
          if (
            domNode.type === 'tag' &&
            domNode.name === 'div' &&
            domNode.attribs.class?.includes('bookNow_content')
          ) {
            const findDomNodeData = (node) => {
              if (node.data) {
                return node.data;
              }
              return node.children.map((n) => findDomNodeData(n)).flat();
            };
            const data = findDomNodeData(domNode);
    
            return <BookNow hotelName={data[0]} link={data[1]}></BookNow>;
          }
    
          if (
            domNode.type === 'tag' &&
            domNode.name === 'div' &&
            domNode.attribs.class?.includes('cta')
          ) {
            const cleanContentEditable = (node) => {
              if (node.attribs && node.attribs.contenteditable !== undefined) {
                delete node.attribs.contenteditable;
              }
    
              if (node.attribs && node.attribs.class?.includes('ck-widget')) {
                node.attribs.class = node.attribs.class.replace('ck-widget', '');
              }
    
              if (
                node.attribs &&
                node.attribs.class?.includes(
                  'ck-editor__editable ck-editor__nested-editable'
                )
              ) {
                node.attribs.class = node.attribs.class.replace(
                  'ck-editor__editable ck-editor__nested-editable',
                  ''
                );
              }
    
              if (node.children) {
                node.children.forEach(cleanContentEditable);
              }
    
              return node;
            };
    
            const cleanedNode = cleanContentEditable(domNode);
            const header = cleanedNode.children.find((child) =>
              child.attribs?.class.includes('cta-header')
            );
            const subheader = cleanedNode.children.find((child) =>
              child.attribs?.class.includes('cta-subheader')
            );
            const button = cleanedNode.children.find((child) =>
              child.attribs?.class.includes('cta-button')
            );
    
            return (
              <div className={styles.cta}>
                {header?.children[0].data && (
                  <h3 className={styles.ctaHeader}>{header?.children[0].data}</h3>
                )}
                {subheader?.children[0].data && (
                  <p className={styles.ctaSubheader}>
                    {subheader?.children[0].data}
                  </p>
                )}
                {button?.children[0].data && (
                  <a href={button?.attribs?.href} className={styles.ctaButton}>
                    {button?.children[0].data}
                  </a>
                )}
              </div>
            );
          }
    
          if (
            domNode.type === 'tag' &&
            domNode.name === 'p' &&
            domNode.children[0].name === 'iframe'
          ) {
            delete domNode.children[0].attribs.height;
            delete domNode.children[0].attribs.width;
            delete domNode.children[0].attribs.allowfullscreen;
            return (
              <div className={styles.youtubeIframe}>
                <iframe {...domNode.children[0].attribs} />
              </div>
            );
          }
    
          if (
            domNode.type === 'tag' &&
            domNode.name === 'div' &&
            (domNode.attribs.class === 'image-carousel-inline-large' ||
              domNode.attribs.class?.includes('carousel_content'))
          ) {
            const carouselImages = [];
    
            domNode.children.forEach((child, index) => {
              if (
                child.attribs &&
                (child.attribs.class === 'image-inline' ||
                  child.attribs.class === 'image' ||
                  child.attribs.class === 'image-style-side')
              ) {
                const figcaption = child.children.find(
                  (node) => node.name === 'figcaption'
                );
                carouselImages.push({
                  src: findImageNode(child).attribs.src,
                  figcaption:
                    !!figcaption?.children.length && figcaption.children[0],
                  alt:
                    child.children[0].attribs.alt ||
                    `${article.title} - ${index}`,
                });
              }
            });
            return (
              <div className={styles.carouselWrapper}>
                <BCCarousel>
                  {carouselImages.map((img, index) => (
                    <div
                      ref={imageRef}
                      key={img.src}
                      className={styles.imageInline}
                      style={{ height: mediaHeight }}
                    >
                      <figure className="image-inline">
                        <Image
                          src={setImageUrl(img.src)}
                          alt={img.alt}
                          blurDataURL={
                            index && !img.src?.includes('/_next/image')
                              ? `/_next/image?url=${setImageUrl(img.src)}&w=16&q=1`
                              : null
                          }
                          layout="fill"
                          objectFit="cover"
                          placeholder={
                            index && !img.src?.includes('/_next/image')
                              ? 'blur'
                              : null
                          }
                        />
                        {img.figcaption && img.figcaption.children && (
                          <figcaption>
                            <a
                              {...img.figcaption.attribs}
                              rel="nofollow"
                              className={styles.carouselLink}
                            >
                              {img.figcaption.children[0].data}
                            </a>
                          </figcaption>
                        )}
                        {img.figcaption && img.figcaption.type === 'text' && (
                          <figcaption>{img.figcaption.data}</figcaption>
                        )}
                      </figure>
                    </div>
                  ))}
                </BCCarousel>
              </div>
            );
          }
          if (
            domNode.type === 'tag' &&
            domNode.name === 'figure' &&
            (domNode.attribs.class === 'image-inline' ||
              domNode.attribs.class === 'image')
          ) {
            const video = findVideoNode(domNode);
            if (video) {
              const videoSrc =
                process.env.mediaUrl +
                video.children
                  .find((node) => node.name === 'source')
                  .attribs.src.replace('/', '');
              return (
                <div className="video">
                  <video autoPlay muted loop>
                    <source src={videoSrc} />
                  </video>
                </div>
              );
            }
            const image = findImageNode(domNode);
            const figcaption = domNode.children.find(
              (node) => node.name === 'figcaption'
            );
            return !image.name ? (
              <></>
            ) : (
              <>
                <div
                  ref={imageRef}
                  className={styles.imageInline}
                  style={{ height: mediaHeight }}
                >
                  {image.name === 'img' && (
                    <figure className="image-inline">
                      <Image
                        src={setImageUrl(image.attribs.src)}
                        alt={
                          image.attribs.alt ||
                          `${article.type} ${article.subtype} - ${article.title}`
                        }
                        layout="fill"
                        objectFit="cover"
                      />
                      {figcaption?.children[0] &&
                        figcaption?.children[0].name === 'a' && (
                          <figcaption>
                            <a
                              {...figcaption.children[0].attribs}
                              className={styles.carouselLink}
                              rel="noreferrer noopener nofollow"
                            >
                              {figcaption.children[0].children[0].data}
                            </a>
                          </figcaption>
                        )}
                      {figcaption?.children[0] && figcaption.name === 'figcaption' && (
                        <figcaption>
                          <span className={styles.carouselLink}>
                            {figcaption.children[0].data}
                          </span>
                        </figcaption>
                      )}
                    </figure>
                  )}
                  {image?.parent?.name === 'a' && (
                    <a {...image.parent.attribs} rel="noreferrer noopener nofollow">
                      <figure className="image-inline">
                        <Image
                          src={setImageUrl(image.attribs.src)}
                          layout="fill"
                          objectFit="cover"
                        />
                        {figcaption?.children[0] &&
                          figcaption?.children[0].name === 'a' && (
                            <figcaption>
                              <a
                                rel="noreferrer noopener nofollow"
                                {...figcaption.children[0].attribs}
                                className={styles.carouselLink}
                              >
                                {figcaption.children[0].children[0].data}
                              </a>
                            </figcaption>
                          )}
                        {!!figcaption?.children.length && (
                          <figcaption>{figcaption.children[0].data}</figcaption>
                        )}
                      </figure>
                    </a>
                  )}
                </div>
              </>
            );
          }
        },
    };

    return options;
}
