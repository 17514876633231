import cn from 'classnames';
import parse from 'html-react-parser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { createRef, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { setImageUrl } from '../../utils/setImageUrl';
import styles from './OfferPage.module.scss';
import dynamic from 'next/dynamic';
import { BCBreadcrumbs } from '../index';
import { ReviewItem } from '../Lists/ReviewItem';
import { getTextEditorParseOptions } from '../utils/textEditorParseOptions';
import { OffersListSection } from '../OffersListSection/OffersListSection';
import Button from '@material-ui/core/Button';
import { Alert, createTheme, IconButton, Select, Snackbar, ThemeProvider, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Link from 'next/link';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SortIcon from '@mui/icons-material/Sort';
import qs from 'qs';

const CallToSignUp = dynamic(() => import('../CallToSignUp/CallToSignUp'));
const RecommendationsList = dynamic(() =>
  import('../RecommendationsList/RecommendationsList')
);

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#00454A',
    },
    dark: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});

export function OfferPage({
  article,
  recommendation,
  authCompleted,
}) {
  const defaultFilters = {
    origin: 'All',
    destination: 'All',
    class: 'Any class',
    sort: null
  };
  const userStore = useSelector((state) => state.user.value);
  const router = useRouter();
  const [mediaHeight, setMediaHeight] = useState(0);
  const [mediaWidth, setMediaWidth] = useState(0);
  const imageRef = createRef();
  const [countryOffers, setCountryOffers] = useState([...article.offers]);
  const [filters, setFilters] = useState(defaultFilters);
  const [cabinClasses, setCabinClasses] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [openedOfferId, setOpenedOfferId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: 'success',
  });

  useEffect(() => {
    if (imageRef?.current) {
      setMediaHeight(imageRef.current.offsetWidth / 1.78);
      setMediaWidth(imageRef.current.offsetWidth);
    }
  }, [imageRef]);

  const options = getTextEditorParseOptions({article, mediaWidth, mediaHeight, imageRef, styles});
  const root = parse(article.content, options);
  let metaDescription = '';
  const parseTextFromNode = (node) => {
    if (typeof node !== 'string') {
      if (node && Array.isArray(node.props.children)) {
        node.props.children.forEach((child) => {
          parseTextFromNode(child);
        });
      } else if (node) {
        parseTextFromNode(node.props.children);
      }
    } else {
      metaDescription += node;
    }
  };
  parseTextFromNode(root[0]);

  useEffect(() => {
    if (article.offers?.length > 0 && userStore?.country) {
      setCountryOffers(article.offers.filter((offer) => offer.country.toUpperCase() === userStore?.country?.toUpperCase()));

      const uniqueOrigins = new Map();

      article.offers.forEach((offer) => {
        if (offer.country.toUpperCase() === userStore?.country?.toUpperCase()) {
          if (!uniqueOrigins.has(offer.origin.code)) {
            uniqueOrigins.set(offer.origin.code, offer.origin);
          }
        }
      });

      setOrigins(Array.from(uniqueOrigins.values()).sort((a, b) => a.name.localeCompare(b.name)));
      const uniqueDestinationCodes = new Set();
      const destinations = [];

      article.offers.forEach((offer) => {
        if (offer.country.toUpperCase() === userStore.country.toUpperCase()) {
          if (!uniqueDestinationCodes.has(offer.destination.code)) {
            uniqueDestinationCodes.add(offer.destination.code);
            destinations.push(offer.destination);
          }
        }
      });

      setDestinations(destinations.sort((a, b) => a.name.localeCompare(b.name)));
    }

    const query = qs.parse(router.query);
    if (Object.keys(query).length && query.offerid) {
      const offer = article.offers.find((offer) => offer.id === +query.offerid);
      if (offer) {
        setOpenedOfferId(offer.id);
      } else {
        setOpenSnackbar({
          open: true,
          message: 'Offer is not found or not available any more',
          type: 'error',
        });
      }
    }
  }, [article, userStore?.country]);

  useEffect(() => {
    const cabinClasses = [...CABIN_CLASSES].reverse();
    setCabinClasses(cabinClasses);
  }, []);

  useEffect(() => {
    if (!filters.resetFilters) {
      const filteredOffers = article.offers.filter((offer) => {
        return (
        
          (filters.origin === 'All' || offer.origin.code === filters.origin) &&
          (filters.destination === 'All' || offer.destination.code === filters.destination) &&
          (filters.class === 'Any class' || offer.cabinClass === filters.class)
        );
      });

      setCountryOffers([...filteredOffers]);
    }

    if (filters.resetFilters) {
      setCountryOffers([...article.offers.filter((offer) => offer.country.toUpperCase() === userStore.country.toUpperCase())]);
      setFilters(defaultFilters);
    }

    if (filters.sort?.price) {
      handlePriceSort(filters.sort.price);
    }

    if (filters.sort?.destination) {
      handleDestinationSort(filters.sort.destination);
    }

  
  }, [filters])

  const handlePriceSort = (sortType) => {
    const sortedOffers = [...countryOffers];
    if (sortType === 'asc') {
      sortedOffers.sort((a, b) => a.price - b.price);
    } else if (sortType === 'desc') {
      sortedOffers.sort((a, b) => b.price - a.price);
    } else {
      // TODO: Sord by order
      sortedOffers.sort((a, b) => {
        const dateA = Date.parse(a.expirationDate);
        const dateB = Date.parse(b.expirationDate);
        return dateB - dateA;
      });
    }
    setCountryOffers(sortedOffers);
  };

  const handleDestinationSort = (sortType) => {
    const sortedOffers = [...countryOffers];
    if (sortType === 'asc') {
      sortedOffers.sort((a, b) => a.destination.name.localeCompare(b.destination.name));
    } else if (sortType === 'desc') {
      sortedOffers.sort((a, b) => b.destination.name.localeCompare(a.destination.name));
    } else {
      sortedOffers.sort((a, b) => {
        const dateA = Date.parse(a.expirationDate);
        const dateB = Date.parse(b.expirationDate);
        return dateB - dateA;
      });
    }
    setCountryOffers(sortedOffers);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const renderFilters = ({ setFieldValue, values }) => {
    return (
      <>
        <div className={cn(styles.filterSmaller)}>
          {origins && (
            <Select
              value={values.origin}
              onChange={(event) => {
                setFieldValue('origin', event.target.value);
              }}
              defaultValue={'All'}
              fullWidth
              name={'origin'}
            >
              <MenuItem value={'All'}>- Any origin -</MenuItem>
              {origins.map((origin) => (
                <MenuItem key={origin.code} value={origin.code}>
                  {origin.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        <div className={cn(styles.filterSmaller)}>
          {destinations && (
            <Select
              value={values.destination}
              onChange={(event) => {
                setFieldValue('destination', event.target.value);
              }}
              defaultValue={'All'}
              fullWidth
              name={'destination'}
            >
              <MenuItem value={'All'}>- Any destination -</MenuItem>
              {destinations.map((destination) => (
                <MenuItem key={destination.code} value={destination.code}>
                  {destination.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        <div className={cn(styles.filterSmaller)}>
          {cabinClasses && (
            <Select
              value={values.class}
              onChange={(event) => {
                setFieldValue('class', event.target.value);
              }}
              fullWidth
              defaultValue={'Any class'}
              name={'class'}
            >
              <MenuItem value={'Any class'}>- Any class -</MenuItem>
              {cabinClasses.map((cabin) => (
                <MenuItem key={cabin.value} value={cabin.value}>
                  {cabin.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Head>
        <title>{article.title} - BusinessClass.com</title>
        {article.contentMetaData?.seoHtml === 'true' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <meta name="description" content={metaDescription} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="businessclass.com" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={metaDescription} />
        <link
          rel="canonical"
          href={`${process.env.apiUrl}${router.asPath.split('?')[0]}`}
        />
        <meta name="twitter:image" content={setImageUrl(article.image)} />
        <meta
          property="og:title"
          content={article.contentMetaData?.metaTitle || article.title}
        />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={setImageUrl(article.image)} />
        <meta property="og:description" content={metaDescription} />
      </Head>
      <div className={cn(styles.article, 'font-libre')}>
        <div className={styles.topContainer}>
          <div className="container">
            <BCBreadcrumbs headerText={article.title} />
            <div className={styles.overline}>Fly Business Class for less!</div>
            <h1 className={cn(styles.title, 'font-fragment')}>
              {article.title}
            </h1>

            <h4 className="font-libre text-base">{article.subtitle}</h4>
            {/* {article.image && (
              <div className={styles.mainImage}>
                {article.image.length === 1 && (
                  <Image
                    src={setImageUrl(article.image[0]?.image)}
                    layout="fill"
                    objectFit="cover"
                    alt={article.image[0]?.altText}
                  />
                )}
              </div>
            )} */}
          </div>
        </div>
        <div className="container">
          <div className="sm:w-full mx-auto sm:px-4 my-6">
            {article.offers?.length > 0 && (
              <div className={styles.offersFilters}>
                <ThemeProvider theme={theme}>
                  <Formik
                    enableReinitialize
                    initialValues={filters}
                    validate={(value) => setFilters({ ...value })}
                  >
                    {({ setFieldValue, values, resetForm }) => (
                      <Form>
                        <div className={styles.filtersContainer}>
                          {renderFilters({ setFieldValue, values })}
                          <div className={styles.sortButton}>
                            <Tooltip title="Sort by price">
                              <IconButton
                                color="inherit"
                                type="reset"
                                onClick={() => {
                                  if (filters.sort?.price === 'desc') {
                                    setFieldValue('sort', {
                                      price: 'asc',
                                    });
                                  }
                                  if (filters.sort?.price === 'asc') {
                                    setFieldValue('sort', {
                                      price: null,
                                    });
                                  }
                                  if (!filters.sort?.price) {
                                    setFieldValue('sort', {
                                      price: 'desc',
                                    });
                                  }
                                }}
                                aria-label="clear"
                                sx={{
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '50%',
                                  color: '#0A0A0A',
                                  width: '56px',
                                  height: '56px',
                                  marginRight: '1rem',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <SortIcon />
                                {filters.sort?.price === 'desc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(90deg)',
                                    }}
                                  />
                                )}
                                {filters.sort?.price === 'asc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(-90deg)',
                                    }}
                                  />
                                )}

                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Sort alphabetically by destination name">
                              <IconButton
                                color="inherit"
                                type="reset"
                                onClick={() => {
                                  if (filters.sort?.destination === 'desc') {
                                    setFieldValue('sort', {
                                      destination: 'asc',
                                    });
                                  }
                                  if (filters.sort?.destination === 'asc') {
                                    setFieldValue('sort', {
                                      destination: null,
                                    });
                                  }
                                  if (!filters.sort?.destination) {
                                    setFieldValue('sort', {
                                      destination: 'desc',
                                    });
                                  }
                                }}
                                aria-label="clear"
                                sx={{
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '50%',
                                  color: '#0A0A0A',
                                  width: '56px',
                                  height: '56px',
                                  marginRight: '1rem',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <SortByAlphaIcon />
                                {filters.sort?.destination === 'desc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(90deg)',
                                    }}
                                  />
                                )}
                                {filters.sort?.destination === 'asc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(-90deg)',
                                    }}
                                  />
                                )}

                              </IconButton>
                            </Tooltip>
                          </div>
                          {Object.keys(filters).some((key) => filters[key] !== defaultFilters[key]) && (
                            <Tooltip title="Reset filters">
                              <IconButton
                                color="inherit"
                                type="reset"
                                onClick={() => {
                                  resetForm({ values: defaultFilters });
                                  setFilters({ resetFilters: true });
                                }}
                                aria-label="clear"
                                sx={{
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '50%',
                                  color: '#0a0a0a',
                                  width: '56px',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </ThemeProvider>
             </div>
            )}

            {userStore?.country && countryOffers?.length > 0 && (
              <>
                <div
                  className={cn(
                    styles.offers,
                    'grid lg:grid-cols-3 gap-6 grid-cols-1 mb-8'
                  )}
                >
                  <OffersListSection
                    offers={countryOffers}
                    searchSource="Offer page"
                    openedOfferId={openedOfferId}>
                  </OffersListSection>
                </div>
              </>
            )}

            {!countryOffers.length && Object.keys(filters).some((key) => filters[key] !== defaultFilters[key]) && (
              <div className={styles.noOffers}>
                <h3 className='font-libre'>No offers available for your filters</h3>
                <p>Please change or reset you filters to view more offers</p>
              </div>
            )}

            {!article.offers?.length && (
              <div className={styles.noOffers}>
                <h3 className='font-libre'>No offers currently available</h3>
                <p>You could check other airlines offer pages or come back later</p>

                <Link href="/offers" className='font-libre'>Check all offer pages</Link>
              </div>
            )}

            {article.offers?.length > 0 && !countryOffers?.length && Object.keys(filters).every((key) => filters[key] === defaultFilters[key]) && (
              <div className={styles.noOffers}>
                <h3 className='font-libre'>No offers available for your country</h3>
                <p>Please select another country or view all offers</p>

                <Button
                  color="secondary"
                  variant="contained"
                  type='button'
                  sx={{
                    borderRadius: '4px',
                    marginLeft: '0.5rem',
                    color: '#ffffff',
                    minWidth: '140px',
                    backgroundColor: '#0a0a0a',
                    '&:hover': {
                      color: '#ffffff',
                      backgroundColor: '#0a0a0a'
                    },
                  }}
                  onClick={(evt) => {
                    setCountryOffers([...article.offers]);
                    setOrigins(
                      Array.from(new Set(article.offers.map((offer) => offer.origin.code))).map(
                        (code) => article.offers.find((offer) => offer.origin.code === code).origin
                      )
                    );
                    setDestinations(
                      Array.from(new Set(article.offers.map((offer) => offer.destination.code))).map(
                        (code) => article.offers.find((offer) => offer.destination.code === code).destination
                      )
                    );
                    evt.stopPropagation();
                  }}
                >
                  Show all available offers
                </Button>
              </div>
            )}

            <div className="content">{root}</div>
          </div>

          {article.airlineReviews?.length > 0 && (
            <div className="container my-8">
               <div className={cn(styles.reviewList, 'lg:grid lg:grid-cols-2 lg:gap-10')}>
                  {article.airlineReviews.map((review, i) => {
                    return (
                      <div key={i}
                        className={cn(((article.airlineReviews.length === 1) || (article.airlineReviews.length > 2 && i === article.airlineReviews.length - 1)) && styles.halfWidth, styles.review, 'mb-3')}>
                        <ReviewItem
                          type="Airline"
                          review={review}
                          url={`/airlines/reviews/${review.textId}`}
                        />
                      </div>
                    );
                  })}
                </div>
            </div>
          )}
          
        </div>
      </div>
      {userStore && !userStore.userid && (
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="w-full mx-auto my-20 sm:mb-10">
              {inView && (
                <CallToSignUp
                  country={userStore?.country}
                  authCompleted={authCompleted}
                  sourcePage={'Article'}
                />
              )}
            </div>
          )}
        </InView>
      )}
      <div className="container flex justify-center items-center sm:w-full mt-20">
        <RecommendationsList items={recommendation} />
      </div>

      <Snackbar
        open={openSnackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={openSnackbar.type}
          sx={{ width: '100%' }}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
