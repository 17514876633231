import { AccordionDetails } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cn from 'classnames';
import styles from './ScrollableContent.module.scss';
import { useState } from 'react';

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
  borderBottom: '1px solid #0000001f',
}));
const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  () => ({
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginBottom: 0,
    },
  })
);
const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});

const ScrollableContent = ({
  heading,
  content,
  style = {},
  headingClass,
  isCusomHeading,
  expanded = () => {},
  isExpanded = false,
}) => {
  const [isLocalExpanded, setLocalExpanded] = useState(isExpanded);

  const handleAccordionChange = (event, newExpanded) => {
    setLocalExpanded(newExpanded);
    expanded(event, newExpanded); // Call the parent callback if needed
  };

  return (
    <div className={cn(style ? style : styles.container)}>
      <ThemeProvider theme={theme}>
        <Accordion expanded={isLocalExpanded} onChange={handleAccordionChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {isCusomHeading && heading}
            {!isCusomHeading && (
              <h3 className={headingClass}>{heading}</h3>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.content}>{content}</div>
          </AccordionDetails>
        </Accordion>
      </ThemeProvider>
    </div>
  );
};

export default ScrollableContent;
