import { NoSsr } from '@material-ui/core';
import cn from 'classnames';
import parse, { domToReact } from 'html-react-parser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { createRef, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import { setImageUrl } from '../../utils/setImageUrl';
import { BCCarousel } from '../Carousel/Carousel';
import Image from 'next/image';
import styles from './Article.module.scss';
import dynamic from 'next/dynamic';
import { AuthorBlock, AuthorPopup, BCBreadcrumbs } from '../index';
import { BookNow } from '../HotelReview/components/BookNow';

const CallToSignUp = dynamic(() => import('../CallToSignUp/CallToSignUp'));
const FlightSearch = dynamic(() => import('../FlightSearch/FlightSearch'));
const RecommendationsList = dynamic(() =>
  import('../RecommendationsList/RecommendationsList')
);
const SocialMediaLinks = dynamic(() =>
  import('../SocialMediaLinks/SocialMediaLinks')
);
const ScrollableContent = dynamic(() =>
  import('../ScrollableContent/ScrollableContent')
);

export function Article({
  article,
  recommendation,
  setLoginDialogState,
  authCompleted,
}) {
  const FlightSearchIndicator = '---FlightSearch---';
  const userStore = useSelector((state) => state.user.value);
  const router = useRouter();
  const [seoSchema, setSeoSchema] = useState(null);
  const [mediaHeight, setMediaHeight] = useState(0);
  const [mediaWidth, setMediaWidth] = useState(0);
  const imageRef = createRef();
  useEffect(() => {
    if (article.contentMetaData?.seoSchema) {
      setSeoSchema(parse(article?.contentMetaData.seoSchema));
    }
  }, [article]);
  useEffect(() => {
    if (imageRef?.current) {
      setMediaHeight(imageRef.current.offsetWidth / 1.78);
      setMediaWidth(imageRef.current.offsetWidth);
    }
  }, [imageRef]);
  const [authorizedContent, setAuthorizedContent] = useState(null);
  const [authorizedContentExpired, setAuthorizedContentExpired] =
    useState(false);
  const options = {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'a') {
        if (domNode.attribs?.href?.includes('http')) {
          if (domNode.attribs?.target === '_blank') {
            domNode.attribs.target = '_blank';
          }
         
          if (domNode.attribs?.rel?.includes('sponsored')) {
            domNode.attribs.rel = 'noreferrer noopener sponsored';
          } else {
            domNode.attribs.rel = 'noreferrer noopener nofollow';
          }
        }
      }
      if (
        domNode.type === 'tag' &&
        domNode.attribs.class === 'image-inline' &&
        domNode.children[0].name === 'video'
      ) {
        const videoSrc = domNode.children[0].children.find(
          (node) => node.name === 'source'
        ).attribs.src;
        return (
          <div className="video">
            <video
              autoPlay
              muted
              loop
              style={{ height: mediaHeight, width: mediaWidth }}
            >
              <source src={videoSrc} />
            </video>
          </div>
        );
      }
      if (
        domNode.type === 'tag' &&
        domNode.name === 'video' &&
        !!domNode.attribs.src
      ) {
        const videoSrc =
          process.env.mediaUrl + domNode.attribs.src.replace('/', '');
        return (
          <video
            autoPlay
            muted
            loop
            style={{
              height: imageRef?.current?.offsetWidth / 1.78,
              width: imageRef?.current?.offsetWidth,
            }}
          >
            <source src={videoSrc} />
          </video>
        );
      }

      // if (domNode.type === 'tag' && domNode.name === 'video' && !domNode.attribs.src) {
      //   const videoSrc = process.env.mediaUrl + domNode.children[0].find(
      //     (node) => node.name === 'source'
      //   ).attribs.src;

      //   return (
      //     <video autoPlay muted loop>
      //       <source src={videoSrc} />
      //     </video>
      //   );
      // }
      const findVideoNode = (domNode) => {
        if (!domNode) return null;
        if (domNode.name === 'video') {
          return domNode;
        } else {
          return findVideoNode(
            domNode.children.find((node) => node.type === 'tag')
          );
        }
      };
      const findImageNode = (domNode) => {
        if (domNode) {
          if (domNode.name === 'img') {
            return domNode;
          } else {
            const figureNode = domNode.children.find(
              (node) => node.name === 'figure'
            );
            if (figureNode) {
              return findImageNode(figureNode);
            } else {
              return findImageNode(
                domNode.children.find((node) => node.type === 'tag')
              );
            }
          }
        } else {
          return <div>Wrong tag</div>;
        }
      };
      if (
        domNode.name === 'div' &&
        domNode.attribs.id === 'scrollable' &&
        domNode.children[0].name !== 'details'
      ) {
        let heading;
        const findHeading = (nodes) => {
          if (nodes.length) {
            nodes.forEach((node) => {
              if (node.attribs?.id === 'heading') {
                heading = [node];
              } else if (node.children?.length) {
                findHeading(node.children);
              }
            });
          }
        };
        findHeading(domNode.children);
        const content = domNode.children.filter(
          (node) => node.attribs?.id !== 'heading'
        );
        return (
          <ScrollableContent
            heading={domToReact(heading, options)}
            content={domToReact(content, options)}
            style={styles.scrollableContent}
          />
        );
      }
      if (domNode.name === 'details') {
        let heading;
        const findHeading = (nodes) => {
          if (nodes.length) {
            nodes.forEach((node) => {
              if (node.name === 'summary') {
                heading = [node.children[0]];
              } else if (node.children?.length) {
                findHeading(node.children);
              }
            });
          }
        };
        findHeading(domNode.children);
        const content = domNode.children.filter(
          (node) => node.attribs.class === 'accordion_content'
        );
        return (
          <ScrollableContent
            heading={domToReact(heading, options)}
            content={domToReact(content, options)}
            style={styles.scrollableContent}
          />
        );
      }
      if (
        domNode.type === 'tag' &&
        domNode.name === 'div' &&
        domNode.attribs.class?.includes('bookNow_content')
      ) {
        const findDomNodeData = (node) => {
          if (node.data) {
            return node.data;
          }
          return node.children.map((n) => findDomNodeData(n)).flat();
        };
        const data = findDomNodeData(domNode);

        return <BookNow hotelName={data[0]} link={data[1]}></BookNow>;
      }

      if (domNode.type === 'tag' && domNode.name === 'div' && domNode.attribs.class?.includes('cta')) {
        const cleanContentEditable = (node) => {
          if (node.attribs && node.attribs.contenteditable !== undefined) {
            delete node.attribs.contenteditable;
          }

          if (node.attribs && node.attribs.class?.includes('ck-widget')) {
            node.attribs.class = node.attribs.class.replace('ck-widget', '');
          }

          if (node.attribs && node.attribs.class?.includes('ck-editor__editable ck-editor__nested-editable')) {
            node.attribs.class = node.attribs.class.replace('ck-editor__editable ck-editor__nested-editable', '');
          }
        
          if (node.children) {
            node.children.forEach(cleanContentEditable);
          }
        
          return node;
        };

        const cleanedNode = cleanContentEditable(domNode);
        const header = cleanedNode.children.find(child => child.attribs?.class.includes('cta-header'));
        const subheader = cleanedNode.children.find(child => child.attribs?.class.includes('cta-subheader'));
        const button = cleanedNode.children.find(child => child.attribs?.class.includes('cta-button'));

        return (
          <div className={styles.cta}>
            {header?.children[0].data && <h3 className={styles.ctaHeader}>{header?.children[0].data}</h3>}
            {subheader?.children[0].data && <p className={styles.ctaSubheader}>{subheader?.children[0].data}</p>}
            {button?.children[0].data && <a href={button?.attribs?.href} className={styles.ctaButton}>{button?.children[0].data}</a>}
          </div>
        );
      }

      if (
        (domNode.type === 'tag' &&
          domNode.name === 'p' &&
          domNode.children[0].data?.trim() === FlightSearchIndicator) ||
        (domNode.type === 'tag' &&
          domNode.name === 'div' &&
          domNode.attribs.class?.includes('flightsearch_content'))
      ) {
        return (
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className={styles.flightSearch}>
                {inView && (
                  <>
                    <div className="w-full hidden xl:block">
                      <FlightSearch
                        contentSearch
                        collapsed={false}
                        viewMode={'mainSearch'}
                        disablePortal={false}
                        airlineName={article.airline?.name}
                        airlineCode={article.airline?.code}
                        airlineClass={
                          CABIN_CLASSES.find((cabin) => cabin.value === 'ECO')
                            .label
                        }
                        searchSource={`${article.type} ${article.subtype}`}
                      />
                    </div>
                    <div className="w-full block xl:hidden">
                      <FlightSearch
                        contentSearch
                        collapsed={false}
                        viewMode={'sideSearch'}
                        disablePortal={false}
                        airlineName={article.airline?.name}
                        airlineCode={article.airline?.code}
                        airlineClass={
                          CABIN_CLASSES.find((cabin) => cabin.value === 'ECO')
                            .label
                        }
                        searchSource={`${article.type} ${article.subtype}`}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </InView>
        );
      }
      if (
        domNode.type === 'tag' &&
        domNode.name === 'p' &&
        domNode.children[0].name === 'iframe'
      ) {
        delete domNode.children[0].attribs.height;
        delete domNode.children[0].attribs.width;
        delete domNode.children[0].attribs.allowfullscreen;
        return (
          <div className={styles.youtubeIframe}>
            <iframe {...domNode.children[0].attribs} />
          </div>
        );
      }

      if (
        domNode.type === 'tag' &&
        domNode.name === 'div' &&
        (domNode.attribs.class === 'image-carousel-inline-large' ||
          domNode.attribs.class?.includes('carousel_content'))
      ) {
        const carouselImages = [];

        domNode.children.forEach((child, index) => {
          if (
            child.attribs &&
            (child.attribs.class === 'image-inline' ||
              child.attribs.class === 'image' ||
              child.attribs.class === 'image-style-side')
          ) {
            const figcaption = child.children.find(
              (node) => node.name === 'figcaption'
            );
            carouselImages.push({
              src: findImageNode(child).attribs.src,
              figcaption:
                !!figcaption?.children.length && figcaption.children[0],
              alt:
                child.children[0].attribs.alt ||
                `${article.type} ${article.subtype} - ${article.title} - ${index}`,
            });
          }
        });
        return (
          <div className={styles.carouselWrapper}>
            <BCCarousel>
              {carouselImages.map((img, index) => (
                <div
                  ref={imageRef}
                  key={img.src}
                  className={styles.imageInline}
                  style={{ height: mediaHeight }}
                >
                  <figure className="image-inline">
                    <Image
                      src={setImageUrl(img.src)}
                      alt={img.alt}
                      blurDataURL={
                        index && !img.src?.includes('/_next/image')
                          ? `/_next/image?url=${setImageUrl(img.src)}&w=16&q=1`
                          : null
                      }
                      layout="fill"
                      objectFit="cover"
                      placeholder={
                        index && !img.src?.includes('/_next/image')
                          ? 'blur'
                          : null
                      }
                    />
                    {img.figcaption && img.figcaption.children && (
                      <figcaption>
                        <a
                          {...img.figcaption.attribs}
                          rel="nofollow"
                          className={styles.carouselLink}
                        >
                          {img.figcaption.children[0].data}
                        </a>
                      </figcaption>
                    )}
                    {img.figcaption && img.figcaption.type === 'text' && (
                      <figcaption>{img.figcaption.data}</figcaption>
                    )}
                  </figure>
                </div>
              ))}
            </BCCarousel>
          </div>
        );
      }
      if (
        domNode.type === 'tag' &&
        domNode.name === 'figure' &&
        (domNode.attribs.class === 'image-inline' ||
          domNode.attribs.class === 'image')
      ) {
        const video = findVideoNode(domNode);
        if (video) {
          const videoSrc =
            process.env.mediaUrl +
            video.children
              .find((node) => node.name === 'source')
              .attribs.src.replace('/', '');
          return (
            <div className="video">
              <video autoPlay muted loop>
                <source src={videoSrc} />
              </video>
            </div>
          );
        }
        const image = findImageNode(domNode);
        const figcaption = domNode.children.find(
          (node) => node.name === 'figcaption'
        );
        return !image.name ? (
          <></>
        ) : (
          <>
            <div
              ref={imageRef}
              className={styles.imageInline}
              style={{ height: mediaHeight }}
            >
              {image.name === 'img' && (
                <figure className="image-inline">
                  <Image
                    src={setImageUrl(image.attribs.src)}
                    alt={
                      image.attribs.alt ||
                      `${article.type} ${article.subtype} - ${article.title}`
                    }
                    layout="fill"
                    objectFit="cover"
                  />
                  {figcaption?.children[0] &&
                    figcaption?.children[0].name === 'a' && (
                      <figcaption>
                        <a
                          {...figcaption.children[0].attribs}
                          className={styles.carouselLink}
                          rel="noreferrer noopener nofollow"
                        >
                          {figcaption.children[0].children[0].data}
                        </a>
                      </figcaption>
                    )}
                  {figcaption?.children[0] && figcaption.name === 'figcaption' && (
                    <figcaption>
                      <span className={styles.carouselLink}>
                        {figcaption.children[0].data}
                      </span>
                    </figcaption>
                  )}
                </figure>
              )}
              {image?.parent?.name === 'a' && (
                <a {...image.parent.attribs} rel="noreferrer noopener nofollow">
                  <figure className="image-inline">
                    <Image
                      src={setImageUrl(image.attribs.src)}
                      layout="fill"
                      objectFit="cover"
                    />
                    {figcaption?.children[0] &&
                      figcaption?.children[0].name === 'a' && (
                        <figcaption>
                          <a
                            rel="noreferrer noopener nofollow"
                            {...figcaption.children[0].attribs}
                            className={styles.carouselLink}
                          >
                            {figcaption.children[0].children[0].data}
                          </a>
                        </figcaption>
                      )}
                    {!!figcaption?.children.length && (
                      <figcaption>{figcaption.children[0].data}</figcaption>
                    )}
                  </figure>
                </a>
              )}
            </div>
          </>
        );
      }
    },
  };
  const root = parse(article.content, options);
  let metaDescription = '';
  const parseTextFromNode = (node) => {
    if (typeof node !== 'string') {
      if (node && Array.isArray(node.props.children)) {
        node.props.children.forEach((child) => {
          parseTextFromNode(child);
        });
      } else if (node) {
        parseTextFromNode(node.props.children);
      }
    } else {
      metaDescription += node;
    }
  };
  parseTextFromNode(root[0]);

  useEffect(async () => {
    if (userStore?.userid && article.contentAuthorizedEnabled) {
      setAuthorizedContent(parse(article.contentAuthorized, options));
    } else if (new Date(article.contentAuthorizedExpired) < new Date()) {
      setAuthorizedContentExpired(true);
    }
  }, [userStore]);

  const openLoginDialog = () => {
    setLoginDialogState({ state: 'login', opened: true, sourcePage: 'Article login link' });
  };
  const openSignUpDialog = () => {
    setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'Article sign up link' });
  };

  return (
    <>
      <Head>
        <title>
          {article.contentMetaData?.metaTitle || article.title} -
          BusinessClass.com
        </title>
        {article.contentMetaData?.seoHtml === 'true' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <meta
          name="description"
          content={article.contentMetaData?.metaDescription || metaDescription}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="businessclass.com" />
        <meta
          name="twitter:title"
          content={article.contentMetaData?.metaTitle || article.title}
        />
        <meta
          name="twitter:description"
          content={article.contentMetaData?.metaDescription || metaDescription}
        />
        <link
          rel="canonical"
          href={
            article.contentMetaData?.canonicalTag
              ? process.env.apiUrl + article.contentMetaData.canonicalTag
              : `${process.env.apiUrl}${router.asPath.split('?')[0]}`
          }
        />
        <meta
          name="twitter:image"
          content={setImageUrl(article.image[0]?.image)}
        />
        <meta
          property="og:title"
          content={article.contentMetaData?.metaTitle || article.title}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={setImageUrl(article.image[0]?.image)}
        />
        <meta
          property="og:description"
          content={article.contentMetaData?.metaDescription || metaDescription}
        />
        {seoSchema}
      </Head>
      <div className={cn(styles.article, 'font-libre')}>
        <div className={styles.topContainer}>
          <div className="container">
            <BCBreadcrumbs headerText={article.title} />
            <div className={styles.overline}>{article.overline}</div>
            <h1 className={cn(styles.title, 'font-fragment')}>
              {article.title}
            </h1>
            <div className={cn(styles.articleMeta)}>
              <AuthorPopup
                userBio={article.userBio}
                isReview={false}
                modifiedDate={
                  article.modifiedDate
                    ? article.modifiedDate
                    : article.published
                }
              ></AuthorPopup>
            </div>
            {article.image.length && (
              <div className={styles.mainImage}>
                {article.image.length === 1 && (
                  <Image
                    src={setImageUrl(article.image[0]?.image)}
                    layout="fill"
                    objectFit="cover"
                    alt={article.image[0]?.altText}
                  />
                )}
                {article.image.length > 1 && (
                  <BCCarousel>
                    {article.image.map((img, index) => (
                      <Image
                        key={img.id}
                        src={setImageUrl(img.image)}
                        blurDataURL={
                          index
                            ? `/_next/image?url=${setImageUrl(
                                img.image
                              )}&w=16&q=1`
                            : null
                        }
                        layout="fill"
                        objectFit="cover"
                        placeholder={index ? 'blur' : null}
                      />
                    ))}
                  </BCCarousel>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <div className="sm:w-full mx-auto sm:px-4">
            <div className="content">
              {root}
              {!userStore?.userid &&
                article.contentAuthorizedEnabled &&
                !authorizedContentExpired && (
                  <div
                    className={cn(
                      styles.authorizedContentContainer,
                      'font-fragment'
                    )}
                  >
                    <div className={styles.gradientContainer}></div>
                    <div className={styles.authorizedContentText}>
                      <a onClick={() => openSignUpDialog()}>Subscribe</a> for
                      free to BusinessClass.com - gain instant access to
                      exclusive offers and content - and receive alerts on the
                      best premium travel offers! Already signed up?{' '}
                      <a onClick={() => openLoginDialog()}>Login</a> here.
                    </div>
                  </div>
                )}
              {((userStore?.userid && article.contentAuthorizedEnabled) ||
                authorizedContentExpired) &&
                authorizedContent}
            </div>
            {article.userBio.content && (
              <div className={styles.authorBioContainer}>
                <AuthorBlock userBio={article.userBio} />
              </div>
            )}
            <NoSsr>
              {typeof document !== 'undefined' && <SocialMediaLinks />}
            </NoSsr>
          </div>
        </div>
      </div>
      {userStore && !userStore.userid && (
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="w-full mx-auto my-20 sm:mb-10">
              {inView && (
                <CallToSignUp
                  country={userStore?.country}
                  authCompleted={authCompleted}
                  sourcePage={'Article'}
                />
              )}
            </div>
          )}
        </InView>
      )}
      <div className="container flex justify-center items-center sm:w-full mt-20">
        <RecommendationsList items={recommendation} />
      </div>
      {!!article.questionsAnswers?.length && (
        <div className="container flex flex-col justify-center items-center sm:w-full mb-8">
          <span className={cn(styles.listTitle, 'text-3xl font-fragment mb-4')}>
            FAQs
          </span>
          {article.questionsAnswers.map((faq) => (
            <ScrollableContent
              key={faq.id}
              heading={faq.question}
              content={faq.answer}
              style={styles.scrollableContent}
            />
          ))}
        </div>
      )}
    </>
  );
}
